<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Progress</div>
                    <div class="card-body mb-n3">
                        <b-progress v-for="(value, index) in values" :key="index" :max="max" :value="value" class="mb-3"
                                    style="max-width: 700px"></b-progress>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Progress Labels</div>
                    <div class="card-body mb-n3">
                        <b-progress v-for="(value, index) in values" :key="index" :max="max" class="mb-3"
                                    style="max-width: 700px">
                            <b-progress-bar :label="getLabel(value)" :value="value"></b-progress-bar>
                        </b-progress>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Contextual Backgrounds</div>
                    <div class="card-body mb-n3">
                        <b-progress v-for="(variant, index) in variations" :key="index" :max="max" :variant="variant"
                                    class="mb-3" style="max-width: 700px">
                            <b-progress-bar :label="variant.toUpperCase()" value="75"></b-progress-bar>
                        </b-progress>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Striped</div>
                    <div class="card-body">
                        <div>
                            <b-progress class="mb-3" striped style="max-width: 700px" variant="primary">
                                <b-progress-bar value="75" animated></b-progress-bar>
                            </b-progress>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Progress',
    data() {
        return {
            variations: ['primary', 'secondary', 'danger', 'warning', 'success', 'info'],
            values: [25, 50, 75, 100],
            max: 100
        }
    },
    methods: {
        getLabel(value) {
            return `${((value / this.max) * 100).toFixed(0)}%`
        },
        getCustomHeight(value) {
            if (value === 100) return '';

            return `${value / 5}px`;
        }
    }
}
</script>
